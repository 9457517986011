import React from 'react';
import { Link } from 'gatsby';

import jobs from '../../assets/jobs.png';

/* This example requires Tailwind CSS v2.0+ */
/* This example requires Tailwind CSS v2.0+ */
export default function ContactUs() {
	return (
		<div className="relative flex items-center justify-center">
			<Link
				to="/contact"
				className="buddy-btn-mobelux lg:w-1/3 w-1/2 absolute z-10"
			>
				Contact Our Team
			</Link>
			{' '}
			<img className=" lg:w-4/5" src={jobs} alt="button" />
		</div>

	);
}
