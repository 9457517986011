import * as React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import HeroGraphic2 from '../assets/HeroGraphic2.svg';
import HeroGraphic from '../assets/HeroGraphic.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';

import ContactUs from '../components/subcomponents/ContactUs';
import SEO from '../components/SEO';

const TeamInformation = loadable(() => import('../components/TeamInformation'));

const OurStory = (props) => (
	<SEO {...props} title="About Buddy | The insurance gateway for software applications" description="Learn about Buddy\'s team and how we came to build our drop-in snippet that enables you to embed any insurance product into your transaction flow in minutes.">
		<main>
			<Header />
			<div className="relative gradient overflow-hidden">
				<img
					src={HeroGraphic}
					alt="Swirl Line"
					className="absolute left-44 top-8 sm:left-96 md:top-9 2xl:top-9 overflow-hidden z-0 xl:pl-96"
				/>
				<div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
					<h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">About Buddy</h1>
					<div className="mt-6 text-xl max-w-3xl z-10">
						<p className="z-10">
							Buddy's founders come from backgrounds in marketing, technology, and insurance, but came together around a
							single mission: to build a more fearless society. After creating the world's first on-demand accident
							insurance and building the technology to make buying insurance simple, fast, and modern, they started to
							realize they were onto something bigger.
						</p>
						<br />
						<p>
							Working with companies that wanted to offer insurance to their customers, Buddy encountered stretched-thin
							tech teams, reluctant to take on another time-consuming integration that wasn't flexible and didn't scale.
						</p>
						<br />
						<p>
							Enter Buddy's universal insurance gateway—the simplest way for any company to offer insurance to their
							customers. Built for businesses but designed for developers, Buddy's single, forward-compatible connection
							enables you to offer and manage insurance without the costly drag on your team's time.
						</p>
						<br />
						<p>
							Buddy is building the future of embedded insurance to make it easier than ever for everyone to
							live a bit more fearlessly.
						</p>
						<br />
					</div>
				</div>
			</div>

			<section>
				<TeamInformation />
			</section>

			<section className="container m-auto md:px-36 md:flex-1 flex-col justify-center items-center" id="join-team-btn">
				<ContactUs />
				<img
					src={HeroGraphic2}
					alt="Swirl Line"
					className="flex mt-4 2xl:left-60 absolute md:mt-10 invisible md:visible"
				/>
			</section>
			<div className="relative">
				<div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
					<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl ">Contact Info</h2>
					<p className="text-gray-500">
						P.O. Box 81
						<br />
						{' '}
						Richmond, VA 23218-0081
						<br />
						<div className="text-base text-gray-500 hover:text-gray-900 mt-4">
							<a
								className=""
								href="tel:+18334628339"
							>
								833.462.8339
							</a>
						</div>
						<div className="text-base text-gray-500 hover:text-gray-900 mt-4">
							<a
								className=""
								href="mailto:buddy@buddy.insure"
							>
								buddy@buddy.insure
							</a>
						</div>
					</p>
				</div>
			</div>

			<Footer />
		</main>
	</SEO>
);

export default OurStory;

export const query = graphql`
	query {
		teamPhoto: file(relativePath: { regex: "/buddyteam-cropped/" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH)
			}
		}
	}
`;

// TODO: Ended on the paragraphs. Need to make them responsive.
// TODO: Where is this (line 68) linking to?
